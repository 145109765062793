@media screen and (min-width: 1100px) {
  .headerText {
    font-size: 130%;
  }
  .headerImage {
    height: 700px;
  }
}

@media screen and (max-width: 1101px) {
  .headerText {
    font-size: 120%;
  }
  .headerImage {
    height: 700px;
  }
}

@media screen and (max-width: 1001px) {
  .headerText {
    font-size: 110%;
  }
  .headerImage {
    height: 600px;
  }
}

@media screen and (max-width: 901px) {
  .headerText {
    font-size: 100%;
  }
  .headerImage {
    height: 550px;
  }
}

@media screen and (max-width: 801px) {
  .headerText {
    font-size: 80%;
  }
  .headerImage {
    height: 450px;
  }
}

@media screen and (max-width: 701px) {
  .headerText {
    font-size: 70%;
  }
  .headerImage {
    height: 400px;
  }
}

@media screen and (max-width: 601px) {
  .headerText {
    font-size: 60%;
  }
  .headerImage {
    height: 350px;
  }
}

.headerImage {
  width: 100%;
  object-fit: cover;
  position: relative;
  opacity: 0.6;
}

.headerText {
  position: absolute;
  top: 5%;
  text-align: justify;
  left: 10%;
  right: 10%;
}

.nav li {
  display: inline;
  margin-left: 10px;
}

.nav {
  background-color: #282c34;
}

.projectRow {
  height: 400px;
  vertical-align: top;
}

.projectImage {
  width: 450px;
  margin-right: 20px;
}

.projectTable {
  width: 90%;
  text-align: justify;
  margin-left: 5%;
  margin-right: 5%;
}

@media all and (max-width: 800px) {
  .projectTable,
  .projectRow,
  .projectRow td {
    display: block;
  }
  .projectRow td {
    position: relative;
  }
  .projectRow {
    padding: 5%;
    width: 100%;
    margin-left: -5%;
  }
  .projectImage {
    margin-top: 10%;
  }
}

.skillsTable {
  width: 100%;
  margin: 0px;
  margin-top: -4px;
  padding: 0px;
  background-color: #bbd0d2;
  text-align: center;
  border-spacing: 0px;
  border-collapse: collapse;
}

.skillsTable th {
  background-color: #9cbbbe;
  border-color: black;
  border-spacing: 0px;
  border-style: none;
  padding: 7px;
}

.skillsTable td {
  padding: 5px;
}

.icons {
  width: 20px;
  margin: 3%;
}

.icons:hover {
  opacity: 0.2;
}

.links {
  margin-left: 35%;
}

footer {
  background-color: #bbd0d2;
  padding-bottom: 60px;
  bottom: 0;
}

h2 {
  text-align: center;
}

.details {
  text-align: center;
}

.dot {
  width: 15px;
}

.scrollButton {
  position: fixed;
  width: 20px;
  left: 95%;
  bottom: 3%;
  z-index: 1;
  cursor: pointer;
}

.scrollButton:hover {
  opacity: 0.2;
}

.skillRow {
  width: 25%;
}
